import React, { lazy } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { PasivikThemeProvider } from 'utilities/theme';
import { Route, Routes } from 'react-router-dom';
import { WEB, DASHBOARD } from 'constants';
import * as Middleware from 'middleware/App';

const ChangeProfile = lazy(() => import('pages/dashboard/profile/change_profile'));
const ChangePassword = lazy(() => import('pages/dashboard/profile/change_password'));
const ReportPage = lazy(() => import('pages/dashboard/pengerjaan/hasil/report-page'));
const Aktifitas = lazy(() => import('pages/dashboard/pengerjaan/hasil/aktifitas'));
const HomePage = lazy(() => import('pages/dashboard/home'));
const ProfilePage = lazy(() => import('pages/dashboard/profile'));
const ModulKategori = lazy(() => import('pages/dashboard/studycase/category'));
const ModulDetail = lazy(() => import('pages/dashboard/studycase/detail'));
const Pengerjaan = lazy(() => import('pages/dashboard/pengerjaan'));

// import component
const LandingPage = lazy(() => import('pages/landing'));
const AuthLoginPage = lazy(() => import('pages/auth/login'));
const AuthForgotPage = lazy(() => import('pages/auth/forgot'));
const AuthResetPage = lazy(() => import('pages/auth/reset'));
const AboutPage = lazy(() => import('pages/landing/about'));
const HowToUsePage = lazy(() => import('pages/landing/how_to_use'));
const FaqPage = lazy(() => import('pages/landing/faq'));
const DemoPage = lazy(() => import('pages/landing/demo'));

const MainLayout = () => {
  return (
    <>
      <ChakraProvider theme={PasivikThemeProvider}>
        <Routes>
          <Route path={WEB.HOMEPAGE} element={<Middleware.Guest render={<LandingPage />} />} />
          <Route path={WEB.ABOUT} element={<Middleware.Guest render={<AboutPage />} />} />
          <Route path={WEB.HOWTOUSE} element={<Middleware.Guest render={<HowToUsePage />} />} />
          <Route path={WEB.FAQ} element={<Middleware.Guest render={<FaqPage />} />} />
          <Route path={WEB.AUTHLOGIN} element={<Middleware.Guest render={<AuthLoginPage />} />} />
          <Route path={WEB.AUTHFORGOT} element={<Middleware.Guest render={<AuthForgotPage />} />} />
          <Route path={WEB.AUTHRESET} element={<Middleware.Guest render={<AuthResetPage />} />} />
          <Route path={WEB.DEMO} element={<Middleware.Guest render={<DemoPage />} />} />
          <Route
            path={DASHBOARD.HOME}
            element={<Middleware.Authenticated render={<HomePage />} />}
          />
          <Route
            path={DASHBOARD.PROFILE}
            element={<Middleware.Authenticated render={<ProfilePage />} />}
          />
          <Route
            path={DASHBOARD.CHANGEPROFILE}
            element={<Middleware.Authenticated render={<ChangeProfile />} />}
          />
          <Route
            path={DASHBOARD.CHANGEPASSWORD}
            element={<Middleware.Authenticated render={<ChangePassword />} />}
          />
          <Route
            path={DASHBOARD.AKTIFITAS}
            element={<Middleware.Authenticated render={<Aktifitas />} />}
          />
          <Route
            path={DASHBOARD.REPORT}
            element={<Middleware.Authenticated render={<ReportPage />} />}
          />

          <Route
            path={DASHBOARD.MODUL_KATEGORI}
            element={<Middleware.Authenticated render={<ModulKategori />} />}
          />
          <Route
            path={DASHBOARD.MODUL_DETAIL}
            element={<Middleware.Authenticated render={<ModulDetail />} />}
          />
          <Route
            path={DASHBOARD.PENGERJAAN}
            element={<Middleware.Authenticated render={<Pengerjaan />} />}
          />
          <Route path="*" element={<>Not Found</>} />
        </Routes>
        {/* </Suspense> */}
      </ChakraProvider>
    </>
  );
};

export default MainLayout;
