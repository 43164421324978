const API = {
  // Constant for api route
  APIBASEURL: 'https://my-json-server.typicode.com/biyono',
  APIURL: 'https://www.pasivik-backend-v2.kalikesia.id/api/'
  // APIURL: 'http://192.168.10.156:8000/api/'
};

const WEB = {
  HOMEPAGE: '/',
  ABOUT: '/about',
  HOWTOUSE: '/how-to-use',
  FAQ: '/faq',
  AUTHLOGIN: '/login',
  AUTHFORGOT: '/forgot',
  AUTHRESET: '/reset',
  DEMO: '/demo'
};

const DASHBOARD = {
  HOME: '/home',
  PROFILE: '/profile',
  CHANGEPROFILE: '/change-profile',
  CHANGEPASSWORD: '/change-password',
  AKTIFITAS: '/aktifitas/:slug',
  REPORT: '/report/:slug',
  MODUL: '/modul',
  MODUL_KATEGORI: '/kategori/:slug',
  MODUL_DETAIL: '/modul/:slug',
  PENGERJAAN: '/pengerjaan/:slug',
  AUTHLOGIN: '/login',
  DEMO: '/demo'
};

export { API, WEB, DASHBOARD };
